import styled from "styled-components"
import media from "styled-media-query"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const Title = styled.h1`
  font-size: 3rem;
  color: var(--main-color);
  text-align: center;
  margin-top: 8rem;
  margin-bottom: 3rem;
  margin-left: 1rem;
  line-height: 1.25;
  font-weight: 600;
  font-family: var(--fontSansSerif);

  span {
    display: inline-block;
    margin: 0 30px;
    background: var(--gradient1);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${media.lessThan("medium")`
    font-size: 2.25rem;
  `}

  ${media.lessThan("small")`
    font-size: 1.5rem;
  `}
`

export const Subtitle = styled.h4`
  font-size: 1.875rem;
  color: #4a5568;
  text-align: center;
  margin: 1rem 0;
  font-family: var(--fontSansSerif);
  margin-bottom: 6rem;
`

export const Container = styled.div`
  width: 66.666667%;
  margin: auto;
  justify-content: space-around;
  flex-wrap: wrap;
  display: flex;
  text-align: center;
`

export const SimpleText = styled.p`
  font-size: 1.25rem;
  color: #4a5568;
  width: 100%;
  margin-bottom: 1rem;
  font-weight: 300;
  font-family: var(--fontSansSerif);
`

export const RecommendedLinks = styled(AniLink)`
  font-size: 1.5rem;
  color: var(--main-color);
  margin-bottom: 1rem;
  font-weight: 600;
  font-family: var(--fontSerif);
`
