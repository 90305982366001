import React, { useEffect } from "react"

import SEO from "components/Seo"
import Layout from "components/Layout"

import * as S from "./styled"

const NotFound = () => {
  useEffect(() => {
    const head = document.getElementsByTagName("head")[0]

    // Create new link Element
    const link = document.createElement("link")

    // set the attributes for link element
    link.rel = "stylesheet"

    link.type = "text/css"

    link.href = "https://afeld.github.io/emoji-css/emoji.css"

    // Append link element to HTML head
    head.appendChild(link)

    return () => {
      link.remove();
    }
  }, [])
  return (
    <Layout>
      <SEO title="Página Não encontrada" />
        <S.Title>
          <i className="em em-unicorn_face"></i>
          <span>
            Você encontrou o Vale dos Unicornios
          </span>
          <i className="em em-unicorn_face"></i>
        </S.Title>
        <S.Subtitle>
          <i className="em em-shushing_face"></i>
          &nbsp; Shhhh... Não conte pra ninguém sobre os Unicórnios &nbsp;
          <i className="em em-shushing_face"></i>
        </S.Subtitle>
        <S.Container className="w-2/3 mx-auto flex flex-wrap justify-around">
          <S.SimpleText className="text-xl mdMin:text-lg sm:text-base w-full font-light text-gray-700 text-center mb-4">
            Não conseguimos encontrar o que você procurava, aproveite e:
          </S.SimpleText>
          <S.RecommendedLinks
            to="/blog"
            className="text-2xl sm:text-xl text-purple-500 font-semibold text-center mb-4"
          >
            Veja os últimos posts do blog
          </S.RecommendedLinks>
          <S.RecommendedLinks
            to="/portfolio"
            className="text-2xl sm:text-xl text-purple-500 font-semibold text-center mb-4"
          >
            Conheça meus trabalhos
          </S.RecommendedLinks>
        </S.Container>
    </Layout>
  )
}

export default NotFound
